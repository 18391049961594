
import { Club } from "@/types/Club/Club";
import { IonButton, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList } from "@ionic/vue";
import { eye, logoTwitter, logoFacebook, logoInstagram, newspaper, notifications } from "ionicons/icons";

import { defineComponent, PropType } from 'vue';
export default defineComponent({
    props: {
        club: {
            type: Object as PropType<Club>,
        }
    },
    components: {
        IonButton,
        IonIcon,
        IonImg,
        IonItem,
        IonItemDivider,
        IonLabel,
        IonList
    },
    methods: {
        toggleGetWebsiteMessages() {
            this.getWebsiteMessages = !this.getWebsiteMessages;
        },
        toggleGetWebsiteNotifications() {
            this.getWebsiteNotifications = !this.getWebsiteNotifications;
        },
        toggleGetTwitterMessages() {
            this.getTwitterMessages = !this.getTwitterMessages;
        },
        toggleGetWTwitterNotifications() {
            this.getTwitterNotifications = !this.getTwitterNotifications;
        },
        toggleGetFacebookMessages() {
            this.getFacebookMessages = !this.getFacebookMessages;
        },
        toggleGetFacebookNotifications() {
            this.getFacebookNotifications = !this.getFacebookNotifications;
        },
        toggleGetInstagramMessages() {
            this.getInstagramMessages = !this.getInstagramMessages;
        },
        toggleGetInstagramNotifications() {
            this.getInstagramNotifications = !this.getInstagramNotifications;
        },

    },
    data: function() {
        return {
            getWebsiteMessages: this.isWebsiteAvailable ? true : false,
            getWebsiteNotifications: this.isWebsiteAvailable ? true : false,
            getTwitterMessages: this.isTwitterAvailable ? true : false,
            getTwitterNotifications: this.isTwitterAvailable ? true : false,
            getFacebookMessages: this.isFacebookAvailable ? true : false,
            getFacebookNotifications: this.isFacebookAvailable ? true : false,
            getInstagramMessages: this.isInstagramAvailable ? true : false,
            getInstagramNotifications: this.isInstagramAvailable ? true : false,
        }
    },
    setup() {
        return {
            eye,
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            notifications
        };
    },

})
