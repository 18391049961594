<template>
  <base-details-layout :page-title="'Vereinseinstellungen'" page-default-back-link="/clubs">
    <club-settings :club="club"></club-settings>
  </base-details-layout>
</template>

<script lang="ts">
import BaseDetailsLayout from "../../../components/base/BaseDetailsLayout.vue";
import ClubSettings from "./components/ClubSettings.vue";
import { useStore } from 'vuex'
import { useRoute } from "vue-router";

import { defineComponent, computed } from 'vue';
export default defineComponent({
  components: {
    BaseDetailsLayout,
    ClubSettings,
  },
  setup() {
      const store = useStore();
      const route = useRoute();

      const club = computed(() => store.getters.club(route.params.uuid));
      return {
        club
      }
  }
});
</script>

<style scoped>
</style>