<template>
    <div>
        <ion-img :src="club?.branding?.logoUrl" :alt="club?.branding?.logoUrl"></ion-img>
    </div>
    <h2 class="ion-text-center">{{ club?.name }}</h2>
    <p class="ion-text-center">{{ club?.sport }}</p>
    <p class="ion-text-center">{{ club?.address.zipCode }} {{ club?.address.city }}</p>
    <ion-list>
        <ion-item-divider v-if="club?.mediaChannel.hasWordpress">
            <ion-icon :icon="newspaper"></ion-icon>
            <ion-label class="item-header">Webseite</ion-label>
        </ion-item-divider>
        <ion-item v-if="club?.mediaChannel.hasWordpress">
            <ion-label>
                <p>Website-Nachrichten abonnieren</p>
            </ion-label>
            <ion-icon
                v-if="getWebsiteMessages"
                :icon="eye"
                slot="end"
                @click="toggleGetWebsiteMessages()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="eye"
                slot="end"
                @click="toggleGetWebsiteMessages()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item v-if="club?.mediaChannel.hasWordpress">
            <ion-label>
                <p>Benachrichtigungen erhalten</p>
            </ion-label>
            <ion-icon
                v-if="getWebsiteNotifications"
                :icon="notifications"
                slot="end"
                @click="toggleGetWebsiteNotifications()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="notifications"
                slot="end"
                @click="toggleGetWebsiteNotifications()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item-divider v-if="club?.mediaChannel.hasTwitter">
            <ion-icon :icon="logoTwitter"></ion-icon>
            <ion-label class="item-header">Twitter</ion-label>
        </ion-item-divider>
        <ion-item v-if="club?.mediaChannel.hasTwitter">
            <ion-label>
                <p>Tweets abonnieren</p>
            </ion-label>
            <ion-icon
                v-if="getTwitterMessages"
                :icon="eye"
                slot="end"
                @click="toggleGetTwitterMessages()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="eye"
                slot="end"
                @click="toggleGetTwitterMessages()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item v-if="club?.mediaChannel.hasTwitter">
            <ion-label>
                <p>Benachrichtigungen erhalten</p>
            </ion-label>
            <ion-icon
                v-if="getTwitterNotifications"
                :icon="notifications"
                slot="end"
                @click="toggleGetWTwitterNotifications()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="notifications"
                slot="end"
                @click="toggleGetWTwitterNotifications()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item-divider v-if="club?.mediaChannel.hasFacebook">
            <ion-icon :icon="logoFacebook"></ion-icon>
            <ion-label class="item-header">Facebook</ion-label>
        </ion-item-divider>
        <ion-item v-if="club?.mediaChannel.hasFacebook">
            <ion-label>
                <p>Posts abonnieren</p>
            </ion-label>
            <ion-icon
                v-if="getFacebookMessages"
                :icon="eye"
                slot="end"
                @click="toggleGetFacebookMessages()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="eye"
                slot="end"
                @click="toggleGetFacebookMessages()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item v-if="club?.mediaChannel.hasFacebook">
            <ion-label>
                <p>Benachrichtigungen erhalten</p>
            </ion-label>
            <ion-icon
                v-if="getFacebookNotifications"
                :icon="notifications"
                slot="end"
                @click="toggleGetFacebookNotifications()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="notifications"
                slot="end"
                @click="toggleGetFacebookNotifications()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item-divider v-if="club?.mediaChannel.hasInstagram">
            <ion-icon :icon="logoInstagram"></ion-icon>
            <ion-label class="item-header">Instagram</ion-label>
        </ion-item-divider>
        <ion-item v-if="club?.mediaChannel.hasInstagram">
            <ion-label>
                <p>Posts abonnieren</p>
            </ion-label>
            <ion-icon
                v-if="getInstagramMessages"
                :icon="eye"
                slot="end"
                @click="toggleGetInstagramMessages()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="eye"
                slot="end"
                @click="toggleGetInstagramMessages()"
                class="grey"
            ></ion-icon>
        </ion-item>
        <ion-item v-if="club?.mediaChannel.hasInstagram">
            <ion-label>
                <p>Benachrichtigungen erhalten</p>
            </ion-label>
            <ion-icon
                v-if="getInstagramNotifications"
                :icon="notifications"
                slot="end"
                @click="toggleGetInstagramNotifications()"
            ></ion-icon>
            <ion-icon
                v-else
                :icon="notifications"
                slot="end"
                @click="toggleGetInstagramNotifications()"
                class="grey"
            ></ion-icon>
        </ion-item>
    </ion-list>
    <ion-button expand="block" fill="solid">Speichern</ion-button>
</template>

<script lang="ts">
import { Club } from "@/types/Club/Club";
import { IonButton, IonIcon, IonImg, IonItem, IonItemDivider, IonLabel, IonList } from "@ionic/vue";
import { eye, logoTwitter, logoFacebook, logoInstagram, newspaper, notifications } from "ionicons/icons";

import { defineComponent, PropType } from 'vue';
export default defineComponent({
    props: {
        club: {
            type: Object as PropType<Club>,
        }
    },
    components: {
        IonButton,
        IonIcon,
        IonImg,
        IonItem,
        IonItemDivider,
        IonLabel,
        IonList
    },
    methods: {
        toggleGetWebsiteMessages() {
            this.getWebsiteMessages = !this.getWebsiteMessages;
        },
        toggleGetWebsiteNotifications() {
            this.getWebsiteNotifications = !this.getWebsiteNotifications;
        },
        toggleGetTwitterMessages() {
            this.getTwitterMessages = !this.getTwitterMessages;
        },
        toggleGetWTwitterNotifications() {
            this.getTwitterNotifications = !this.getTwitterNotifications;
        },
        toggleGetFacebookMessages() {
            this.getFacebookMessages = !this.getFacebookMessages;
        },
        toggleGetFacebookNotifications() {
            this.getFacebookNotifications = !this.getFacebookNotifications;
        },
        toggleGetInstagramMessages() {
            this.getInstagramMessages = !this.getInstagramMessages;
        },
        toggleGetInstagramNotifications() {
            this.getInstagramNotifications = !this.getInstagramNotifications;
        },

    },
    data: function() {
        return {
            getWebsiteMessages: this.isWebsiteAvailable ? true : false,
            getWebsiteNotifications: this.isWebsiteAvailable ? true : false,
            getTwitterMessages: this.isTwitterAvailable ? true : false,
            getTwitterNotifications: this.isTwitterAvailable ? true : false,
            getFacebookMessages: this.isFacebookAvailable ? true : false,
            getFacebookNotifications: this.isFacebookAvailable ? true : false,
            getInstagramMessages: this.isInstagramAvailable ? true : false,
            getInstagramNotifications: this.isInstagramAvailable ? true : false,
        }
    },
    setup() {
        return {
            eye,
            logoTwitter,
            logoFacebook,
            logoInstagram,
            newspaper,
            notifications
        };
    },

})
</script>

<style scoped>
ion-img {
    width: 75%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    padding-top: 1rem;
}

ion-icon {
    color: var(--ion-color-primary);
    margin-right: 0.5rem;
}

.item-header {
    font-size: 1rem;
    color: var(--ion-color-primary);
    font-family: var(--ion-font-family-semi-bold);
}

ion-icon.grey {
    color: #cccccc;
}
</style>